<template>
    <div v-loading="$wait.is('loading.client_profile')">
        <div class="flex flex-col border rounded-lg p-10 pt-8 mb-6">
            <div class="grid grid-cols-2 gap-4 pb-5">
                <div>
                    <p class="mb-1">
                        {{ $t('calendar.client') }}:
                    </p>
                    <el-input :value="clientProfileData.client" readonly />
                </div>
                <div>
                    <p class="mb-1">
                        {{ $t('calendar.email') }}:
                    </p>
                    <el-input :value="clientProfileData.email" readonly />
                </div>
            </div>
            <div class="pb-5 grid grid-cols-2 gap-4">
                <div>
                    <p class="mb-1">
                        {{ $t('calendar.telephone') }}:
                    </p>
                    <el-input :value="clientProfileData.telephone" readonly />
                </div>
                <div>
                    <p class="mb-1">
                        {{ $t('calendar.keys') }}:
                    </p>
                    <el-input :value="clientProfileData.keys_count" readonly />
                </div>
            </div>
        </div>
        <gmap-map
            ref="maps"
            :center="{ lat:Number(clientProfileData.map_lat), lng:Number(clientProfileData.map_lng) }"
            :zoom="14"
            map-type-id="terrain"
            style="height: 400px"
            :options="{
                scrollwheel: false
            }"
        >
            <gmap-marker
                :key="10"
                :position="{ lat:Number(clientProfileData.map_lat), lng:Number(clientProfileData.map_lng)}"
                :clickable="false"
            />
            <gmap-circle
                :center="{lat:Number(clientProfileData.map_lat), lng:Number(clientProfileData.map_lng)}"
                :radius="clientProfileData.geofence_radius"
                :options="{
                    fillColor: '#77b4ff',
                    fillOpacity: 0.2,
                    strokeColor: '#7eb4ff',
                    strokeWeight: 1,
                    clickable: false
                }"
            />
        </gmap-map>
    </div>
</template>
<script>
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
    installComponents: true,
    load:              {
        key:       'AIzaSyCNIRpGZdH8CTBizW-HcGlAhnO_8YHvRNg',
        libraries: 'places,geometry',
        language:  'se',
    },
});

export default {
    props: {
        eventUuid: {
            type:    String,
            default: '',
        },
    },

    data() {
        return {
            clientProfileData: {
                map_lat: 59.3286141,
                map_lng: 18.0626482,
            },
        };
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading.client_profile');
            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/${this.eventUuid}/client_profile`);
                this.clientProfileData = data;
                this.$wait.end('loading.client_profile');
            } catch (e) {
                this.$wait.end('loading.client_profile');
            }
        },
    },
};
</script>
