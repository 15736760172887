var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.$wait.is('loading.client_profile')),expression:"$wait.is('loading.client_profile')"}]},[_c('div',{staticClass:"flex flex-col border rounded-lg p-10 pt-8 mb-6"},[_c('div',{staticClass:"grid grid-cols-2 gap-4 pb-5"},[_c('div',[_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('calendar.client'))+": ")]),_c('el-input',{attrs:{"value":_vm.clientProfileData.client,"readonly":""}})],1),_c('div',[_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('calendar.email'))+": ")]),_c('el-input',{attrs:{"value":_vm.clientProfileData.email,"readonly":""}})],1)]),_c('div',{staticClass:"pb-5 grid grid-cols-2 gap-4"},[_c('div',[_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('calendar.telephone'))+": ")]),_c('el-input',{attrs:{"value":_vm.clientProfileData.telephone,"readonly":""}})],1),_c('div',[_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('calendar.keys'))+": ")]),_c('el-input',{attrs:{"value":_vm.clientProfileData.keys_count,"readonly":""}})],1)])]),_c('gmap-map',{ref:"maps",staticStyle:{"height":"400px"},attrs:{"center":{ lat:Number(_vm.clientProfileData.map_lat), lng:Number(_vm.clientProfileData.map_lng) },"zoom":14,"map-type-id":"terrain","options":{
            scrollwheel: false
        }}},[_c('gmap-marker',{key:10,attrs:{"position":{ lat:Number(_vm.clientProfileData.map_lat), lng:Number(_vm.clientProfileData.map_lng)},"clickable":false}}),_c('gmap-circle',{attrs:{"center":{lat:Number(_vm.clientProfileData.map_lat), lng:Number(_vm.clientProfileData.map_lng)},"radius":_vm.clientProfileData.geofence_radius,"options":{
                fillColor: '#77b4ff',
                fillOpacity: 0.2,
                strokeColor: '#7eb4ff',
                strokeWeight: 1,
                clickable: false
            }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }